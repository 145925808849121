import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/White.svg';
import linkedin from '../assets/img/linkedIn.svg';
import email from '../assets/img/email.svg';
import facebook from "../assets/img/square-facebook-brands-solid.svg";
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from 'react-helmet';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 50);
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const handleLinkClick = () => {
    const navbarCollapse = document.querySelector('.navbar-collapse');
    navbarCollapse.classList.remove('show');
  }

  return (
    <Router>
      <Helmet>
        <title>Al Hamd Tex - Quality Textile Manufacturers and Exporters in Pakistan</title>
        <meta name="description" content="Explore Al Hamd Tex for high-quality textiles including wiping rags and industrial gloves. Contact us for inquiries." />
      </Helmet>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Al Hamd Tex Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link 
                href="#home" 
                className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} 
                onClick={() => { onUpdateActiveLink('home'); handleLinkClick() }}
                aria-label="Go to Home"
              >
                Home
              </Nav.Link>
              <Nav.Link 
                href="#about" 
                className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} 
                onClick={() => { onUpdateActiveLink('about'); handleLinkClick() }}
                aria-label="Learn About Us"
              >
                About Us
              </Nav.Link>
              <Nav.Link 
                href="#products" 
                className={activeLink === 'products' ? 'active navbar-link' : 'navbar-link'} 
                onClick={() => { onUpdateActiveLink('products'); handleLinkClick() }}
                aria-label="View Our Products"
              >
                Products
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.facebook.com/profile.php?id=61566907933740" aria-label="Follow us on Facebook">
                  <img src={facebook} alt="Facebook Icon" />
                </a>
                <a href="https://www.linkedin.com/company/al-hamd-tex/" aria-label="Follow us on LinkedIn">
                  <img src={linkedin} alt="LinkedIn Icon" />
                </a>
                <a href="mailto:contact@alhamdtex.com" aria-label="Email us">
                  <img src={email} alt="Email Icon" />
                </a>
              </div>
              <HashLink to='#connect'>
                <button onClick={handleLinkClick} aria-label="Send an Inquiry">
                  <span>Send an Inquiry</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}