import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Products } from "./components/Products";
import { Services } from "./components/Services";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { AboutUs } from "./components/AboutUs";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Al Hamd Tex - Quality Textile Products Manufacturers and Exporters in Pakistan</title>
        <meta name="description" content="Welcome to Al Hamd Tex, your source for high-quality textiles including industrial gloves, towels, and custom textile solutions." />
      </Helmet>
      <NavBar />
      <Banner />
      <AboutUs />
      <Products />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;