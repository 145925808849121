import React from 'react';
import { Helmet } from 'react-helmet';
import whiteRags from "../assets/img/whiteRags.png";
import whiteFlannelRags from "../assets/img/Recycled White Flannel Rags.png";
import recycleAssortedColoredFleeceKnitsRags from "../assets/img/Recycle Assorted Color fleece knit rags.png";
import recycleWhiteSheetingRags from "../assets/img/Recycle white sheeting rags.png"; 
import recycleAssortedColoredTShirtKnitsRags from "../assets/img/Recycle Assorted Color t-shirt knit rags.png"; 
import newUnbleachKnitRags from "../assets/img/New unbleach single jersey knit rags.png";
import newbleachKnitRags from "../assets/img/New bleach mix knits rags.png";
import newUnbleachMixKnitRags from "../assets/img/New unbleach mix knits rags.png";
import newAssortedCloredSingleJerseyKnitRags from "../assets/img/New assorted colored single jersey knits rags.png";
import newWhiteTerryTowelOverlockedRags from "../assets/img/New white terry towels over lock rags.png";
import newWhiteTerryStitchedTowels from "../assets/img/New white terry stitched towels .png";

import coloredTowel from "../assets/img/coloredTowel.png";
import premiumWhiteTowel from "../assets/img/Premium White Towel.png";
import whiteTowel from "../assets/img/whiteTowel.png";
import kitchenTowel from "../assets/img/kitchenTowel.png";
import kitchenTowel2 from "../assets/img/Kitchen Towel.png";
import bathMat from "../assets/img/Bath Mat.png";


import blueHuckTowel from "../assets/img/Blue Huck Towel.png";
import greyHuckTowel from "../assets/img/Grey Huck Towel.png";
import surgicalTowel from "../assets/img/Surgical Towel.png";
import redShopTowel from "../assets/img/Red Shop Towel.png";
import whiteShopTowel from "../assets/img/White Shop Towel.png";
import washCloth from "../assets/img/Wash Cloth.png";
import cheeseCloth from "../assets/img/Cheese cloth.png";


  
import weldingGloves from "../assets/img/weldingGloves.png";
import yellowDuster from "../assets/img/yellowDuster.png";
import brownIndustrialGloves from "../assets/img/Brown Industrial Gloves.png";
import redIndustrialGloves from "../assets/img/Red Industrial Gloves.png";
import yellowIndustrialGloves from "../assets/img/Yellow Industrial Gloves.png";
import leatherIndustrialGLoves from "../assets/img/Leather Industrial Gloves.png";
import longElbowGLoves from "../assets/img/Long Elbow Gloves.png";
import whiteDottedGloves from "../assets/img/White Dotted Gloves.png";

import terryCottonKnitStainingPads from "../assets/img/Terry Cotton Staining Pads.png";
import crepeBandages from "../assets/img/Crepe Bandages.png";


import ductingCloth5555 from "../assets/img/DuctingCloth5555.png";
import ductingCloth7777 from "../assets/img/DuctingCloth7777.png";
import ductingCloth9999 from "../assets/img/DuctingCloth9999.png";
import ductingClothWhiteComparision7777 from "../assets/img/WhiteDuctingClothComparison7777.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";

export const Products = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="product" id="products">
      <Helmet>
        <title>Our Products | Al Hamd Tex - Quality Textile Cleaning and Industrial Solutions Exporters in Pakistan </title>
        <meta name="description" content="Explore our wide range of high-quality textile products including rags, towels, and industrial gloves. Custom manufacturing available." />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="product-bx wow zoomIn">
              <h2>Our Products</h2>
              <p>Elevating Everyday Essentials<br /> Explore our thoughtfully crafted products<br /> We also manufacture custom made products</p>
              <Carousel responsive={responsive} infinite={true}>
              <div className="item">
                  <img src={terryCottonKnitStainingPads} alt="Terry Cotton Staining Pads" />
                  <h3>Terry Cotton Staining Pads</h3>
                </div>
                <div className="item">
                  <img src={crepeBandages} alt="Crepe Bandages" />
                  <h3>Crepe Bandages</h3>
                </div>
                <div className="item">
                  <img src={coloredTowel} alt="Colored Bath Linen" />
                  <h3>Bath Towel</h3>
                </div>
                <div className="item">
                  <img src={premiumWhiteTowel} alt="Premium White Towel" />
                  <h3>Premium White Towel</h3>
                </div>
                <div className="item">
                  <img src={kitchenTowel} alt="Kitchen Help Duster" />
                  <h3>Kitchen Dusters</h3>
                </div>
                <div className="item">
                  <img src={kitchenTowel2} alt="Kitchen Help Towel" />
                  <h3>Kitchen Towels</h3>
                </div>
                <div className="item">
                  <img src={bathMat} alt="Bath Mat" />
                  <h3>Bath Mat</h3>
                </div>
                <div className="item">
                  <img src={whiteTowel} alt="White Hospitality Towel" />
                  <h3>Hospitality Institutional</h3>
                </div>
                <div className="item">
                  <img src={surgicalTowel} alt="Surgical Towel" />
                  <h3>Surgical Towel</h3>
                </div>
                <div className="item">
                  <img src={blueHuckTowel} alt="Blue Huck Towel" />
                  <h3>Blue Huck Towel</h3>
                </div>
                <div className="item">
                  <img src={greyHuckTowel} alt="Grey Huck Towel" />
                  <h3>Grey Huck Towel</h3>
                </div>
                <div className="item">
                  <img src={redShopTowel} alt="Red Shop Towel" />
                  <h3>Red Shop Towel</h3>
                </div>
                <div className="item">
                  <img src={whiteShopTowel} alt="White Shop Towel" />
                  <h3>White Shop Towel</h3>
                </div>
                <div className="item">
                  <img src={washCloth} alt="Wash CLoth" />
                  <h3>Wash Cloth</h3>
                </div>
                <div className="item">
                  <img src={cheeseCloth} alt="Cheese CLoth" />
                  <h3>Cheese Cloth</h3>
                </div>
                <div className="item">
                  <img src={yellowIndustrialGloves} alt="Yellow Industrial Working Gloves" />
                  <h3>Yellow Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={brownIndustrialGloves} alt="Brown Industrial Working Gloves" />
                  <h3>Brown Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={redIndustrialGloves} alt="Red Industrial Working Gloves" />
                  <h3>Red Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={leatherIndustrialGLoves} alt="Leather Industrial Working Gloves" />
                  <h3>Leather Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={longElbowGLoves} alt="Long Elbow Welding Gloves" />
                  <h3>Welding Gloves</h3>
                </div>
                <div className="item">
                  <img src={whiteDottedGloves} alt="White Dotted Cotton Gloves" />
                  <h3>Dotted Cotton Gloves</h3>
                </div>
                <div className="item">
                  <img src={yellowDuster} alt="Yellow Cleaning Duster" />
                  <h3>Yellow Duster</h3>
                </div>
                <div className="item">
                  <img src={weldingGloves} alt="Industrial Welding Gloves" />
                  <h3>Industrial Support</h3>
                </div>
                <div className="item">
                  <img src={whiteRags} alt="White Rags" />
                  <h3>New White Rags</h3>
                </div>
                <div className="item">
                  <img src={whiteFlannelRags} alt="Recycled White Flannel Rags" />
                  <h3>Recycled White Flannel Rags</h3>
                </div>
                <div className="item">
                  <img src={recycleAssortedColoredFleeceKnitsRags} alt="Recycle Assorted Colored Fleece Knits Rags" />
                  <h3>Recycle Assorted Colored Fleece Knits Rags</h3>
                </div>
                <div className="item">
                  <img src={recycleWhiteSheetingRags} alt="Recycle White Sheeting Rags" />
                  <h3>Recycle White Sheeting Rags</h3>
                </div>
                <div className="item">
                  <img src={recycleAssortedColoredTShirtKnitsRags} alt="Recycle Assorted Colored T-Shirt Knits Rags" />
                  <h3>Recycle Assorted Colored T-Shirt Knits Rags</h3>
                </div>   
                <div className="item">
                  <img src={newUnbleachKnitRags} alt="New Unbleached Single Jersey Knits Rags" />
                  <h3>New Unbleached Single Jersey Knits Rags</h3>
                </div>  
                <div className="item">
                  <img src={newbleachKnitRags} alt="Bleached Mix Knits Rags" />
                  <h3>Bleached Mix Knits Rags</h3>
                </div>   
                <div className="item">
                  <img src={newUnbleachMixKnitRags} alt="New Un-Bleached Mix Knits Rags" />
                  <h3>New UnBleached Mix Knits Rags</h3>
                </div>   
                <div className="item">
                  <img src={newAssortedCloredSingleJerseyKnitRags} alt="New Assorted Colored Single Jersey Knits Rags" />
                  <h3>New Assorted Colored Single Jersey Knits Rags</h3>
                </div> 
                <div className="item">
                  <img src={newWhiteTerryTowelOverlockedRags} alt="New White Terry Towel OverLocked Rags" />
                  <h3>New White Terry Towel OverLocked Rags</h3>
                </div>   
                <div className="item">
                  <img src={newWhiteTerryStitchedTowels} alt="New White Terry Bar Mopes" />
                  <h3>New White Terry Bar Mopes</h3>
                </div>   
                <div className="item">
                  <img src={ductingCloth5555} alt="Ducting Cloth #5555" />
                  <h3>Ducting Cloth #5555</h3>
                </div>
                <div className="item">
                  <img src={ductingCloth7777} alt="Ducting Cloth #7777" />
                  <h3>Ducting Cloth #7777</h3>
                </div>
                <div className="item">
                  <img src={ductingCloth9999} alt="Ducting Cloth #9999" />
                  <h3>Ducting Cloth #9999</h3>
                </div>
                <div className="item">
                  <img src={ductingClothWhiteComparision7777} alt="Comparison of White Ducting Cloth #7777" />
                  <h3>Comparison of White Ducting Cloth #7777</h3>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Decorative Background" />
    </section>
  )
}