import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from "react-bootstrap";
import linkedIn from "../assets/img/linkedIn.svg";
import email from "../assets/img/email.svg";
import facebook from "../assets/img/square-facebook-brands-solid.svg";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Helmet>
        <title>Contact Us | Al Hamd Tex Exports and Manufacturers of Textile Products in Pakistan</title>
        <meta name="description" content="Connect with Al Hamd Tex through our social media or email for inquiries about our textile products." />
      </Helmet>
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={12} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.facebook.com/profile.php?id=61566907933740" aria-label="Follow us on Facebook">
                <img src={facebook} alt="Facebook icon" />
              </a>
              <a href="https://www.linkedin.com/company/al-hamd-tex/" aria-label="Follow us on LinkedIn">
                <img src={linkedIn} alt="LinkedIn icon" />
              </a>
              <a href="mailto:contact@alhamdtex.com" aria-label="Email us">
                <img src={email} alt="Email icon" />
              </a>
            </div>
            <p>Copyright © {currentYear}. All Rights Reserved to Al Hamd Tex - Quality Textile Manufacturers and Exporters.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};