import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from "react-bootstrap";
import contactUs from "../assets/img/contactUs.png";

export const Contact = () => {
  return (
    <section className="contact" id="connect">
      <Helmet>
        <title>Contact Us | Al Hamd Tex - Exporters of Wiping Rags, Industrial Gloves, and More in Pakistan</title>
        <meta name="description" content="Get in touch with Al Hamd Tex for quotations and inquiries about our high-quality wiping rags, industrial gloves, and other textile products. Contact us today!" />
      </Helmet>
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <img src={contactUs} alt="Contact Us" />
          </Col>
          <Col size={12} md={6}>
            <div>
              <h2>Get Quotation</h2>
              {/* Commenting out the form implementation for now
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col size={12} sm={6} className="px-1">
                    <input type="text" value={formDetails.name} placeholder="Your Name" onChange={(e) => onFormUpdate('name', e.target.value)} />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="text" value={formDetails.companyName} placeholder="Company Name" onChange={(e) => onFormUpdate('companyName', e.target.value)} />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                  </Col>
                  <Col size={12} className="px-1">
                    <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                    <button type="submit"><span>{buttonText}</span></button>
                  </Col>
                  {
                    status.message &&
                    <Col>
                      <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                    </Col>
                  }
                </Row>
              </form>
              */}
              <p>If you have any inquiries regarding quotations for our products, please contact us at <a href="mailto:contact@alhamdtex.com">contact@alhamdtex.com</a>.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};