import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card } from 'react-bootstrap';

export const AboutUs = () => {
  return (
    <section className="about-us" id="about">
      <Helmet>
        <title>About Us | Al Hamd Tex - Exporters of Wiping Rags, Industrial Gloves, Towels in Pakistan</title>
        <meta name="description" content="Learn about Al Hamd Tex, a family-run textile company specializing in high-quality wiping rags, industrial gloves, bar mops, and towels. Our commitment to quality and sustainability sets us apart." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Al Hamd Tex",
              "url": "https://www.alhamdtex.com",
              "logo": "https://www.alhamdtex.com/logo.png",
              "description": "Al Hamd Tex specializes in manufacturing and exporting high-quality wiping rags, industrial gloves, bar mops, and towels.",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-123-456-7890",
                "contactType": "Customer Service"
              }
            }
          `}
        </script>
      </Helmet>
      <Container>
        <h2 className="text-center mb-4">About Us</h2>
        <Row>
          <Col md={12} lg={6} className="mb-4">
            <Card className="h-100 custom-card">
              <Card.Body>
                <Card.Title>Our Story</Card.Title>
                <Card.Text>
                  Al Hamd Tex was founded by Muhammad Shamim Quraishi, a passionate entrepreneur with over 25 years of experience in the textile industry. Starting from the ground up, Mr. Quraishi has built a legacy of quality and trust in manufacturing and exporting textiles such as towels, wiping rags, industrial gloves, bar mops, and shop towels. After facing challenges, Mr. Quraishi decided to establish Al Hamd Tex to continue his commitment to excellence and innovation.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} lg={6} className="mb-4">
            <Card className="h-100 custom-card">
              <Card.Body>
                <Card.Title>Why We Exist</Card.Title>
                <Card.Text>
                  Al Hamd Tex was created to bring high-quality, ethically made textiles to the market. The business was born from a vision of delivering dependable products while upholding integrity and trust in every transaction. Al Hamd Tex addresses a need for durable, affordable textile solutions, from towels to industrial gloves, crafted to meet the rigorous standards of international customers. Mr. Quraishi, who built his career from the ground up, created Al Hamd Tex to offer a modern, family-run approach to an industry he knows inside and out.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6} className="mb-4">
            <Card className="h-100 custom-card">
              <Card.Body>
                <Card.Title>Our Purpose</Card.Title>
                <Card.Text>
                  At Al Hamd Tex, we are driven by a mission to deliver top-notch textile products that cater to the diverse needs of our global customers. We are committed to sustainability, ethical practices, and continuous improvement. We believe in the power of quality textiles to improve lives and support sustainable practices, and that’s why we do what we do.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} lg={6} className="mb-4">
            <Card className="h-100 custom-card">
              <Card.Body>
                <Card.Title>Our Values</Card.Title>
                <Card.Text>
                  Integrity, quality, and innovation are at the heart of Al Hamd Tex. Our identity is deeply rooted in Mr. Quraishi's extensive industry experience and unwavering dedication. By blending traditional craftsmanship with modern technology, we offer products that are both durable and innovative. Our brand stands as a symbol of trust and excellence in the textile market.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="h-100 custom-card">
              <Card.Body>
                <Card.Title >Our Commitment</Card.Title>
                <Card.Text>
                  We believe in building strong, lasting relationships with our customers, partners, and the community. Al Hamd Tex is more than just a business; it is a family-run endeavor that strives to make a positive impact on the world. We are committed to sourcing materials responsibly, reducing waste, and promoting fair labor practices. Our commitment to sustainability and social responsibility is reflected in every aspect of our operations.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};