import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/banner.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(200 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Manufacturers", "Exporters", "25 years of craftsmanship"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Helmet>
        <title>Al Hamd Tex - Exporters of Wiping Rags, Industrial Gloves, Cleaning Supplies and Towels in Pakistan</title>
        <meta name="description" content="Al Hamd Tex offers high-quality wiping rags, industrial gloves, bar mops, and towels. With over 25 years of craftsmanship, we provide unmatched expertise in textile manufacturing and exporting." />
      </Helmet>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Redefining Textiles</span>
                  <h1>{`AL-HAMD TEX! `} 
                    <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Manufacturers", "Exporters", "with 25 years of craftsmanship" ]'>
                      <span className="wrap">{text}</span>
                    </span>
                  </h1>
                  <p>
                    Immerse yourself in the perfect blend of luxury, innovation, and sustainability. Directed by personnel having more than two decades of industry experience, from engineering textiles to managing day-to-day operations. Proudly exported to the US, Europe, and the Middle East, we guarantee impeccable quality and unmatched expertise in our products, including wiping rags, industrial gloves, bar mops, and towels.
                  </p>
                  <button onClick={() => console.log('connect')}>Request Quote <ArrowRightCircle size={30} /></button>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="High-Quality Textiles from Al Hamd Tex" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}